import {useContext, useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";

import {fetchFieldForm} from "redux/slices/fieldSlice";
import {fetchGroups, fetchStores} from "redux/slices/businessSlice";
import {fetchMapAddresses} from "redux/slices/mapSlice";

import Form from "components/form/Form";
import Preloader from "components/preloader/SpinPlaceholderComponent";

import {AppContext} from "providers/AppContextProvider";
import {getUser} from "helpers/storage";
import {loadingStatus} from "helpers/fetcher";

const Id = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {search} = useLocation();
  const dispatch = useDispatch();

  const {fieldForm} = useSelector((state) => state.field);
  const {groups, stores} = useSelector((state) => state.business);
  const {mapAddresses} = useSelector((state) => state.map);
  const {alert, setMenuCondition, setCrumbs} = useContext(AppContext);

  const [reload, setReload] = useState(0);
  const [ff, setFF] = useState([]);

  const searchType = useMemo(() => new URLSearchParams(search).get("type"), [search]);

  useEffect(() => {
    // Запись хлебных крошек
    setCrumbs([
      {name: searchType, url: "/form?type=" + searchType},
      {name: "№ " + fieldForm.data.document_number, url: ""}
    ]);

    // Если доступ просмотровый, запрет редактирования
    const copy = Object.assign({}, fieldForm.data);
    if (fieldForm.data && getUser().accessLevel < 2) {
      copy.provided = 1;
      copy.done = 1;
    }
    setFF(copy);
  }, [fieldForm.data]);

  useEffect(() => {
    dispatch(fetchFieldForm(id));
    dispatch(fetchGroups());
    dispatch(fetchStores());
    dispatch(fetchMapAddresses());
  }, []);

  return (
    <>
      <title>
        Редактировать {searchType.toLowerCase()} № {fieldForm.data.document_number}
      </title>

      {!ff.id ? (
        <p>Загрузка...</p>
      ) : (
        <Form
          alert={alert}
          setMenuCondition={setMenuCondition}
          groups={groups.data}
          type={searchType}
          form={ff}
          storages={stores.data}
          AddressList={mapAddresses.data}
          userLevel={() => getUser().accessLevel}
        />
      )}
    </>
  );
};

// export async function getServerSideProps({params}){
// 	const apiToken = "Bearer ~ybqF7}S9G9a5@8}aIex";

// 	// Запрос формы
// 	const formResponse = await axios.get("fields/getForm/" + params.id, {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const form = formResponse.data.result;

// 	// Запрос названий групп
// 	const groupsResponse = await axios.get("businessRu/getGroups", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const groups = groupsResponse.data.result;

// 	// Запрос складов
// 	const storagesResponse = await axios.get("businessRu/getStores", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const storages = storagesResponse.data.result;

// 	// Запрос всех адресов
// 	const addressListResponse = await axios.get("map/getAllAddresses", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const addressList = addressListResponse.data.result;

// 	return {props: {form, groups, storages, addressList}}
// }

export default Id;
