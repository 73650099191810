import {USER_SETTINGS} from "constants/settings";

export const getToken = () => {
  const token = localStorage.getItem('token');
  return token;
};

export const saveToken = (token) => {
  return localStorage.setItem('token', token);
};

export const removeToken = () => {
  localStorage.removeItem('token');
};

export const getUser = () => {
  const user = getToken() ? JSON.parse(atob(getToken().split(".")[1])) : {};
  const accessLevel = user.level ? parseInt(atob(user.level)) : null;
  return {
    ...user,
    accessLevel,
  };
};

export const getUserSetting = (key) => {
  const settings = JSON.parse(localStorage.getItem("settings") || "{}");
  return settings[key] ?? USER_SETTINGS[key];
};

export const setUserSetting = (key, val) => {
  const settings = JSON.parse(localStorage.getItem("settings") || "{}");
  settings[key] = val;
  localStorage.setItem("settings", JSON.stringify(settings));
};

export const saveNewChargeDocumentIntoLocalStorage = (newChargeDocument) => {
  localStorage.setItem('newChargeDocument', JSON.stringify(newChargeDocument));
};
