import {useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import clsx from 'clsx';
import {toast} from 'react-toastify';

import {fetchChargesAsyncAction, postDocumentAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {resetChargeDocumentAction} from 'redux/slices/documents/documents';
import {getChargeDocument} from 'redux/slices/documents/selectors';
import {redirectToRoute} from 'redux/actions';

import {AppContext} from 'providers/AppContextProvider';

import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';
import {AppRoute} from 'constants/routes';

import styles from './styles.module.scss';

const ChargesFloatPanel = () => {
  const dispatch = useDispatch();

  const {alert} = useContext(AppContext);

  const chargeDocument = useSelector(getChargeDocument);
  const chargeDocumentItems = chargeDocument.documentItems;
  const chargeDocumentNumber = chargeDocument.documentNumber;
  const chargeDocumentDate = chargeDocument.documentDate;
  const storage = chargeDocument.storage;
  const employee = chargeDocument.employee;
  const comment = chargeDocument.comment;

  // Проверка ввода номера документа
  const checkDocumentNumberInserted = () => {
    if (!chargeDocumentNumber) {
      toast.error('Укажите номер документа!');
      return false;
    } else {
      return true;
    }
  };

  // Проверка ввода имени бригадира
  const checkEmployeeInserted = () => {
    if (!employee) {
      toast.error('Укажите бригадира!');
      return false;
    } else {
      return true;
    }
  };

  // Проверка выбора склада
  const checkStorageSelected = () => {
    if (!storage) {
      toast.error('Выберите склад!');
      return false;
    } else {
      return true;
    }
  };

  // Проверка ввода элементов документа
  const checkLinesFilledCorrectly = () => {
    if (chargeDocumentItems.length) {
      const isEveryLineFilledCorrectly = chargeDocumentItems.every((line) => {
        if (line.userPositions.length) {
          const isEveryPositionFilledCorrectly = line.userPositions.every((position) => {
            const isValueInserted = !!position.amount;
            const isValueGreaterThanZero = String(position.amount) > 0;
    
            return isValueInserted && isValueGreaterThanZero;
          });
    
          return isEveryPositionFilledCorrectly;
        } else {
          toast.error('Значения не введены или введены неверно!');
          return false;
        }
      });

      if (!isEveryLineFilledCorrectly) {
        toast.error('Значения не введены или введены неверно!');
      }
  
      return isEveryLineFilledCorrectly;
    } else {
      toast.error('Значения не введены или введены неверно!');
      return false;
    }
  };

  // Сохранение документа
  const saveDocument = async () => {
    await dispatch(postDocumentAsyncAction({
      type: DocumentTypeNameMap[DocumentType.CHARGE],
      form: chargeDocumentItems,
      document: {
        number: chargeDocumentNumber,
        date: chargeDocumentDate
      },
      storage,
      employee,
      comment
    }));
  };

  // Обработчик кнопки сохранения документа
  const handleSaveButtonClick = () => {
    const handler = async () => {
      // Выполнить проверку заполнения номера документа и имени бригадира
      const isDocumentNumberInserted = checkDocumentNumberInserted();
      const isEmployeeInserted = checkEmployeeInserted();
      const isStorageSelected = checkStorageSelected();
      const isLinesFilledCorrectly = checkLinesFilledCorrectly();

      if (isDocumentNumberInserted && isEmployeeInserted && isStorageSelected && isLinesFilledCorrectly) {
        await saveDocument()
          .then(() => {
            dispatch(fetchChargesAsyncAction());
            dispatch(resetChargeDocumentAction());
            localStorage.removeItem('newChargeDocument');
          })
          .then(() => dispatch(redirectToRoute(AppRoute.Document.CHARGES)));
      }
    };

    alert('Сохранить документ?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  return (
    <div className={clsx(styles.floatPanel, styles.controlsPanel)}>
      {/* Первые 8 (восемь) кнопок отображаются по условию: */}
      {/* (form.done === 0 || (!form.id && !form.supply_id)) && userLevel() > 1 */}

      {/* Кнопка удаления документа */}
      {/* type !== "Поступление" && form.id > 0 && form.provided === 0 */}
      <span
        className="roundIcon dangerIcon material-icons"
        onClick={() => {}}
        title="Удалить"
      >
        delete_sweep
      </span>

      {/* Кнопка проведения документа в системе */}
      {/* (type === "Поступление" || form.id > 0) && form.provided === 0 */}
      <span
        className="roundIcon material-icons"
        onClick={() => {}}
        title="Провести в Системе"
      >
        checklist
      </span>

              {/* Кнопка отмены проведения */}
              {/* form.provided === 1 */}
              <span
                className="roundIcon material-icons dangerIcon"
                onClick={() => {}}
                title="Отменить проведение"
              >
                undo
              </span>

              {/* Кнопка проведения в  */}
              {/* form.provided === 1 */}
              <span
                className="roundIcon material-icons successIcon"
                onClick={() => {}}
                title="Провести в Базе"
              >
                done
              </span>

      {/* Кнопка обновления остатков */}
      {/* type === "Списание" && form.id > 0 && form.provided === 0 */}
      <span
        className="roundIcon material-icons"
        onClick={() => {}}
        title="Обновить остатки"
      >
        refresh
      </span>

      {/* Кнопка копирования документа */}
      {/* form.id > 0 */}
      <span
        className="roundIcon material-icons"
        onClick={() => {}}
        title="Скопировать документ"
      >
        copy
      </span>

      {/* Кнопка сохранения документа */}
      {/* form.provided === 0 */}
      <span
        className="roundIcon material-icons"
        onClick={handleSaveButtonClick}
        title="Сохранить документ"
      >
        save
      </span>

      {/* Кнопка записи коррекции документа */}
      {/* Если документ проведён и тип документа не "Поступление" */}
      {/* form.provided === 1 && type !== "Поступление" */}
      <span
        className="roundIcon material-icons"
        onClick={() => {}}
        title="Записать коррекцию"
      >
        inventory
      </span>








      {/* Кнопка скачивания документа */}
      <span
        className="roundIcon material-icons"
        onClick={() => {}}
        title="Скачать"
      >
        download
      </span>

      {/* Кнопка открытия модального окна примечания */}
      <span
        className="roundIcon material-icons"
        onClick={() => {}}
        title="Примечание"
      >
        maps_ugc
      </span>

      {/* Кнопка перехода в руководство пользователя */}
      <span
        className="roundIcon material-icons"
        onClick={() => {}}
        title="Инструкция"
      >
        quiz
      </span>
    </div>
  );
};

export default ChargesFloatPanel;
