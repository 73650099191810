import {useEffect, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Input from 'components/input/InputComponent';
import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
import ChargesTableRow from 'components/charges-table-row/charges-table-row';
import AddNewLineButton from 'components/add-new-line-button/add-new-line-button';

import {fetchGroups, fetchStores} from 'redux/slices/businessSlice';
import {fetchGoods} from 'redux/slices/goodSlice';
import {
  setCurrentChargeDocumentItemsAction,
  setChargeDocumentAction,
  setChargeDocumentStorageAction,
  setChargeDocumentEmployeeAction,
  setChargeDocumentDate,
  setChargeDocumentNumber
} from 'redux/slices/documents/documents';
import {
  getChargeDocument,
  getChargeDocumentStorage,
  getChargeDocumentEmployee
} from 'redux/slices/documents/selectors';

import ChargesFloatPanel from 'components/charges-float-panel/charges-float-panel';

import {AppContext} from 'providers/AppContextProvider';

import {AppRoute} from 'constants/routes';
import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';
import {emptyLine} from 'constants/document-empty-line';

import styles from './styles.module.scss';

const ChargesNew = () => {
  const dispatch = useDispatch();

  // Классы таблицы
  const tableClasses = [styles.mainTable, styles.top0, "table", "table-responsive"];

  const {alert, setCrumbs} = useContext(AppContext);

  const chargeDocument = useSelector(getChargeDocument);
  const documentNumber = chargeDocument.documentNumber;
  const documentDate = chargeDocument.documentDate;
  const chargeDocumentItems = chargeDocument.documentItems;
  const storage = useSelector(getChargeDocumentStorage);
  const employee = useSelector(getChargeDocumentEmployee);

  const storages = useSelector((state) => state.business).stores.data;
  const groups = useSelector((state) => state.business).groups.data;

  // Проверка склада
  const storageChecker = () => {
    if (storage === "") {
      alert("Выберите склад!", "danger")
    };
  };

  // Добавление строки
  const addLine = (index = -1, firstDeleted = false) => {
    const copy = structuredClone(chargeDocumentItems);

    // Копирование или вставка новой строки
    if (index > -1) {
      copy.push(JSON.parse(JSON.stringify(copy[index])));
    } else {
      copy.push(JSON.parse(JSON.stringify(emptyLine)));
      copy[0].userPositions.forEach((pos, ind) => {
        if (ind > 0) {
          copy[copy.length - 1].userPositions.push(
            JSON.parse(JSON.stringify(emptyLine.userPositions[0]))
          );
        }
      });

      if (storage) {
        copy[copy.length - 1].storage = storage;
      }

      if (copy[0].toStorage) {
        copy[copy.length - 1].toStorage = copy[0].toStorage;
      }
    }

    dispatch(setCurrentChargeDocumentItemsAction(copy));
  };

  // Удаление строки
  const deleteLine = (index) => {
    // Если удалена последняя строка, то автоматически вставляем новую пустую
    if (chargeDocumentItems.length === 1) {
      dispatch(setCurrentChargeDocumentItemsAction([emptyLine]));
    } else {
      const copy = structuredClone(chargeDocumentItems);

      copy.splice(index, 1);
      dispatch(setCurrentChargeDocumentItemsAction(copy));
    }
  };

  // Выбор и установка склада
  const handleStorageInputChange = (storage) => {
    dispatch(setCurrentChargeDocumentItemsAction([emptyLine]));
    dispatch(setChargeDocumentStorageAction(storage));
  };

  // Установка имени бригадира
  const handleEmployeeInputChange = (employee) => {
    dispatch(setChargeDocumentEmployeeAction(employee));
  };

  // Обработчик поля ввода номера документа
  const handleDocumentNumberInputChange = (name, value) => {
    dispatch(setChargeDocumentNumber(value));
  };

  // Если уже есть начатый документ, продолжить его заполнение
  useEffect(() => {
    const incompletedChargeDocument = JSON.parse(localStorage.getItem('newChargeDocument'));
    if (incompletedChargeDocument) {
      dispatch(setChargeDocumentAction(incompletedChargeDocument));
    }
  }, []);

  // Запрос данных с сервера
  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchGoods());
    dispatch(fetchStores());
    // dispatch(fetchMapAddresses());
  }, []);

  // Установка даты нового документа
  useEffect(() => {
    if (!documentDate) {
      const date = new Date();
      const documentDate = String(date.getDate()).padStart(2, "0") +
        "." +
        String(date.getMonth() + 1).padStart(2, "0") +
        "." +
        date.getFullYear() +
        " " +
        String(date.getHours()).padStart(2, "0") +
        ":" +
        String(date.getMinutes()).padStart(2, "0")

      dispatch(setChargeDocumentDate(documentDate));
    }
  }, []);

  // Запись хлебных крошек
  useEffect(() => {
    setCrumbs([
      {name: DocumentTypeNameMap[DocumentType.CHARGE], url: AppRoute.Document.CHARGES},
      {name: 'Новый документ', url: ''},
    ]);
  }, []);

  return (
    <div>
      <ChargesFloatPanel/>

      <table className={tableClasses.join(" ")}>
        <thead className={styles.top0 + " theadBordered thead-dark"}>
          <tr>
            <th colSpan="2" className={styles.borderNone}>
              <Input
                type="text"
                name="document-number"
                autocomplete="off"
                placeholder="Номер документа"
                value={documentNumber}
                setValue={handleDocumentNumberInputChange}
              />
            </th>
            <th className={styles.borderNone + " " + styles.dateInput}>
              {documentDate}
            </th>
            <th>
              <Input
                type="text"
                name="employee"
                value={employee}
                setValue={(name, value) => handleEmployeeInputChange(value)}
                placeholder="Бригадир"
                title={employee}
              />
            </th>
            <th colSpan="3">Остатки по складам:</th>
            <th>
              {
                !storage
                  ? (
                      <CustomSearchSelect
                        inputName='storage'
                        defaultValue={storage}
                        options={storages}
                        onChange={handleStorageInputChange}
                        placeholder='Склад'
                      />
                    )
                  : storage
              }
            </th>
          </tr>
          <tr>
            <th rowSpan="2">№</th>
            <th
              rowSpan="2"
              // className={sortName === "name" ? "sort sorted" : "sort"}
              // onClick={() => sortFn("name")}
            >
              <span>Название</span>
            </th>
            <th
              rowSpan="2"
              // className={sortName === "article" ? "sort sorted" : "sort"}
              // onClick={() => sortFn("article")}
            >
              <span>Артикул</span>
            </th>
            <th
              rowSpan="2"
              // className={sortName === "b_group" ? "sort sorted" : "sort"}
              // onClick={() => sortFn("b_group")}
            >
              Группа
            </th>
            <th rowSpan="2">Всего:</th>
            <th colSpan="2">В том числе:</th>
            <th rowSpan="2">Расход</th>
          </tr>
          <tr>
            <th>Кол-во</th>
            <th>Адрес</th>
          </tr>
        </thead>
        <tbody>
          {
            storage && (
              <>
                {/* Строки таблицы */}
                {
                  chargeDocumentItems.map((line, lineIndex) => (
                    <ChargesTableRow
                      key={lineIndex}
                      line={line}
                      lineIndex={lineIndex}
                      provided={false}
                      storageChecker={storageChecker}
                      groups={groups}
                      deleteLine={deleteLine}
                    />
                  ))
                }
                
                <AddNewLineButton title='Добавить строку' addLine={addLine}/>
                <tr style={{height: "300px"}}/>
              </>
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default ChargesNew;
