import {Fragment} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';

import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
import Input from 'components/input/InputComponent';

import {setCurrentChargeDocumentItemsAction} from 'redux/slices/documents/documents';
import {getChargeDocument} from 'redux/slices/documents/selectors';

import cl from "styles/pages/[fieldId].module.scss";

const ChargesTableRow = ({
  line,
  lineIndex,
  provided,
  storageChecker,
  groups,
  deleteLine
}) => {
  const dispatch = useDispatch();

  const chargeDocument = useSelector(getChargeDocument);
  const chargeDocumentItems = chargeDocument.documentItems;
  const goods = useSelector((state) => state.good).goods;

  const itemsNames = structuredClone(chargeDocumentItems).map((itm) => itm.name);

  // Метод изменения наименования и артикула (и группы, если не выбрана)
  const changeValue = (index, selectElementName, value) => {
    if (selectElementName === "datas" && chargeDocumentItems[index].article === "") return;

    const copiedLines = structuredClone(chargeDocumentItems);

    // Проверка дублирующей позиции
    let error = false;
    copiedLines.forEach((cpItm) => (cpItm[selectElementName] === value && selectElementName !== "b_group") ? error = true : null);

    if (error) {
      alert("Такой товар уже есть в списке!", "danger");
      copiedLines.splice(index, 1);
    } else {
      copiedLines[index][selectElementName] = value;

      if (selectElementName === 'name') {
        const plant = goods.find((plant) => plant.name === value);
        const plantArticle = plant.article;
        const businessGroupName = groups.find((group) => group.id === plant.b_group_id).name;

        copiedLines[index].article = plantArticle;
        copiedLines[index].b_group = businessGroupName;
      }

      if (selectElementName === 'article') {
        const plant = goods.find((plant) => plant.article === value);
        const plantName = plant.name;
        const businessGroupName = groups.find((group) => group.id === plant.b_group_id).name;

        copiedLines[index].name = plantName;
        copiedLines[index].b_group = businessGroupName;
      }
    }

    dispatch(setCurrentChargeDocumentItemsAction(copiedLines));
  };

  // Получить список наименований для селекта для вновь добавленной строки (в самом низу)
  const filterSelectComponentOptions = (selectComponentName) => {
    // Отсеиваем те товары, которые уже есть в инвентаризации
    const filteredPlants = goods.filter((plant) => !itemsNames.includes(plant.name));
    const filteredArticles = filteredPlants.map((plant) => ({
      id: plant.b_group_id,
      name: plant.article,
      value: plant.article
    }));

    // Если в новой строке группа уже выбрана, то фильтруем список для селекта ещё и по группе
    if (line.b_group && groups.length) {
      const groupId = groups.find((gr) => gr.name === line.b_group).id;
      const filteredByGroupPlants = filteredPlants.filter((plant) => plant.b_group_id === groupId);
      const filteredByGroupArticles = filteredByGroupPlants.map((plant) => ({
        id: plant.b_group_id,
        name: plant.article,
        value: plant.article
      }));

      switch(selectComponentName) {
        case 'name':
          return filteredByGroupPlants;
        case 'article':
          return filteredByGroupArticles;
        default:
          return;
      };
    }

    switch(selectComponentName) {
      case 'name':
        return filteredPlants;
      case 'article':
        return filteredArticles;
      default:
        return;
    };
  };

  // Изменение позиций
  const handlePositionDataInputChange = (indexes, name, value) => {
    if (chargeDocumentItems[indexes.lineIndex].name) {
      const copy = structuredClone(chargeDocumentItems);

      copy[indexes.lineIndex].userPositions[indexes.positionIndex][name] = value;

      if (name === "amount" && copy[indexes.lineIndex].amounts[indexes.positionIndex].address !== "") {
        copy[indexes.lineIndex].userPositions[indexes.positionIndex].address = copy[indexes.lineIndex].amounts[indexes.positionIndex].address;
      }

      if (name === "amount_current") {
        copy[indexes.lineIndex].amounts[indexes.positionIndex]["amount_after"] = value;
        copy[indexes.lineIndex].userPositions[indexes.positionIndex]["amount_after"] = value;
        if (value > 0) {
          copy[indexes.lineIndex].userPositions[indexes.positionIndex]["address_current"] = copy[indexes.lineIndex].amounts[indexes.positionIndex]["address"];
        } else {
          copy[indexes.lineIndex].userPositions[indexes.positionIndex]["address_current"] = "";
        }
      }

      if (name === "amount_after") {
        copy[indexes.lineIndex].amounts[indexes.positionIndex]["amount_after"] = copy[indexes.lineIndex].amounts[indexes.positionIndex]["amount"] === ""
          ? value
          : parseInt(copy[indexes.lineIndex].amounts[indexes.positionIndex]["amount"]) +
            parseInt(value);
      }

      if (name === "address_after") {
        copy[indexes.lineIndex].amounts[indexes.positionIndex]["address_after"] = value;
      }

      // Изменение второго общего кол-ва
      if (name === "amount_current" || name === "amount_after") {
        copy[indexes.lineIndex].totalAmount_after = 0;
        copy[indexes.lineIndex].amounts.forEach((am) => {
          copy[indexes.lineIndex].totalAmount_after += parseInt(am.amount_after);
        });
      }

      dispatch(setCurrentChargeDocumentItemsAction(copy));
    } else {
      toast.error('Выберите наименование');
    }
  };

  return (
    <Fragment>
      {line.amounts.map((position, positionIndex) => (
        <tr key={positionIndex} className={line.isError ? cl.error : ''}>
          {
            positionIndex === 0 && (
              <>
                <td rowSpan={line.amounts.length}>{lineIndex + 1} ({line.good_id})</td>
                <td
                  rowSpan={line.amounts.length}
                  className={cl.name}
                  onClick={storageChecker}
                >
                  {
                    !provided && !line.name ? (
                      <CustomSearchSelect
                        inputName='name'
                        defaultValue={line.name}
                        options={filterSelectComponentOptions('name')}
                        onChange={(value) => changeValue(lineIndex, 'name', value)}
                        placeholder='Наименование'
                      />
                    ) : (
                      line.name
                    )
                  }
                </td>
                <td rowSpan={line.amounts.length} onClick={storageChecker}>
                  {
                    !provided && !line.article ? (
                      <CustomSearchSelect
                        inputName='article'
                        defaultValue={line.article}
                        options={filterSelectComponentOptions('article')}
                        onChange={(value) => changeValue(lineIndex, 'article', value)}
                        placeholder='Артикул'
                      />
                    ) : (
                      line.article
                    )
                  }
                </td>
                <td rowSpan={line.amounts.length} onClick={storageChecker}>
                  {
                    !provided && !line.b_group ? (
                      <CustomSearchSelect
                        inputName='b_group'
                        defaultValue={line.b_group}
                        options={groups}
                        onChange={(value) => changeValue(lineIndex, 'b_group', value)}
                        placeholder='Группа'
                      />
                    ) : (
                      line.b_group
                    )
                  }
                </td>
                <td rowSpan={line.amounts.length} className={cl.right}>
                  {new Intl.NumberFormat('ru-RU').format(line.totalAmount)}
                </td>
              </>
            )
          }

          <td className={cl.right}>
            {new Intl.NumberFormat('ru-RU').format(position.amount)}
          </td>
          <td className={cl.address}>{position.address}</td>

          <td className={cl.right}>
            {
              !provided ? (
                <Input
                  type='number'
                  name='amount'
                  placeholder='Кол-во'
                  value={line.userPositions[positionIndex]?.amount}
                  setValue={handlePositionDataInputChange}
                  currentIndex={{lineIndex, positionIndex}}
                />
              ) : (
                new Intl.NumberFormat('ru-RU').format(
                  line.userPositions[positionIndex]?.amount
                )
              )
            }
          </td>

          {
            positionIndex === 0 && !provided && (
              <td className='iconed' rowSpan={line.amounts.length}>
                <span
                  className='roundIcon material-icons'
                  onClick={() => deleteLine(lineIndex)}
                  title='Удалить строку'
                >
                  delete_outline
                </span>
              </td>
            )
          }
        </tr>
      ))}
    </Fragment>
  );
};

export default ChargesTableRow;
