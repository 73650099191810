import ChargesListItem from 'components/charges-list-item/charges-list-item';

import styles from './styles.module.scss';

const ChargesListItems = ({chargesListItems}) => {

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Системный №</th>
          <th>Тип</th>
          <th>Дата в Системе</th>
          <th>Склад</th>
          <th/>
          <th/>
        </tr>
      </thead>
      <tbody>
        {
          chargesListItems.map((charge) => (
            <ChargesListItem charge={charge}/>
          ))
        }
      </tbody>
    </table>
  );
};

export default ChargesListItems;
