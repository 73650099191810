export const getChargesList = (state) => state.documents.charges.list;
export const getChargeDocument = (state) => state.documents.charges.document;
export const getChargeDocumentStorage = (state) => state.documents.charges.document.storage;
export const getChargeDocumentEmployee = (state) => state.documents.charges.document.employee;

export const getPostingsList = (state) => state.documents.postings.list;
export const getPostingDocument = (state) => state.documents.postings.document;

export const getShiftingsList = (state) => state.documents.shiftings.list;
export const getShiftingDocument = (state) => state.documents.shiftings.document;
