import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';

import {resetChargeDocumentAction} from 'redux/slices/documents/documents';

import {getUser} from 'helpers/storage';
import {AppContext} from 'providers/AppContextProvider';

import {AppRoute} from 'constants/routes';
import {
  NEW_CHARGE_BUTTON_CAPTION,
  CHARGES_ARCHIVE_BUTTON_CAPTION,
  CHARGE_USER_MANUAL_BUTTON_CAPTION
} from './constants';

import styles from './styles.module.scss';

const ChargesListButtonsBlock = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLevel = getUser().accessLevel;

  const {alert} = useContext(AppContext);

  const handleCreateNewChargeButtonClick = () => {
    // Проверить, есть ли в localStorage данные с ключом 'newDocument'
    const incompletedDocumentAlreadyExists = !!localStorage.getItem('newChargeDocument');

    // Если есть, то предложить (задать вопрос) пользователю, хочет ли он его продолжить
    if (incompletedDocumentAlreadyExists) {
      alert('Ранее вы уже создали, но не сохранили новый документ списания. Продолжить работу с ним?', 'default', 0, [
        {
          text: 'Да',
          handler: () => {
            navigate(AppRoute.Document.CHARGES_NEW);
            alert("", "default", 1);
          }
        },
        {
          text: 'Нет',
          handler: () => {
            // Очистка localStorage
            localStorage.removeItem('newChargeDocument');

            // Очистка стейта
            dispatch(resetChargeDocumentAction([]));

            navigate(AppRoute.Document.CHARGES_NEW);
            alert("", "default", 1);
          }
        }
      ])
    } else {
      navigate(AppRoute.Document.CHARGES_NEW);
    }
  };

  return (
    <div className={styles.wrapper}>
      {/* Кнопка добавления нового документа списания */}
      {
        userLevel > 1 && (
          <div>
            <button
              className={clsx(styles.button, styles.new)}
              onClick={handleCreateNewChargeButtonClick}
            >
              <span>
                {NEW_CHARGE_BUTTON_CAPTION}
              </span>
            </button>
          </div>
        )
      }

      <div className={styles['inner-wrapper']}>
        {/* Кнопка перехода в архив списаний */}
        <div>
          <button
            className={clsx(styles.button, styles.regular)}
            onClick={() => navigate(AppRoute.Archive.CHARGES)}
          >
            <span>
              {CHARGES_ARCHIVE_BUTTON_CAPTION}
            </span>
          </button>
        </div>
        
        {/* Кнопка открытия раздела о списаниях в руководстве пользователя */}
        <div>
          <button
            className={clsx(styles.button, styles.regular)}
            onClick={() => window.open(AppRoute.UserManual.CHARGE)}
          >
            <span>
              {CHARGE_USER_MANUAL_BUTTON_CAPTION}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChargesListButtonsBlock;
