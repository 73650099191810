import {createSlice} from '@reduxjs/toolkit';

import {
  fetchChargesAsyncAction,
  fetchPostingsAsyncAction,
  fetchShiftingsAsyncAction
} from './documents-api-actions';

import {saveNewChargeDocumentIntoLocalStorage} from 'helpers/storage';

const initialState = {
  charges: {
    list: [],
    document: {
      documentNumber: '',
      documentDate: '',
      employee: '',
      storage: '',
      comment: '',
      documentItems: []
    }
  },
  postings: {
    list: [],
    document: {
      documentNumber: '',
      documentDate: '',
      employee: '',
      storage: '',
      comment: '',
      documentItems: []
    }
  },
  shiftings: {
    list: [],
    document: {
      documentNumber: '',
      documentDate: '',
      employee: '',
      storage: '',
      comment: '',
      documentItems: []
    }
  }
};

export const documents = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    // Используется для очистки стейта при отказе пользователя продолжить
    // заполнение начатого документа и после сохранения заполненного документа
    resetChargeDocumentAction: (state) => {
      state.charges.document = {
        documentNumber: '',
        documentDate: '',
        employee: '',
        storage: '',
        comment: '',
        documentItems: []
      }
    },
    // Используется для заполнения стейта при согласии пользователя продолжить заполнение начатого документа
    setChargeDocumentAction: (state, action) => {
      state.charges.document = action.payload;
    },
    setChargeDocumentDate: (state, action) => {
      state.charges.document.documentDate = action.payload;
      // saveNewChargeDocumentIntoLocalStorage(state.charges.document);
    },
    setChargeDocumentNumber: (state, action) => {
      state.charges.document.documentNumber = action.payload;
      saveNewChargeDocumentIntoLocalStorage(state.charges.document);
    },
    setChargeDocumentStorageAction: (state, action) => {
      state.charges.document.storage = action.payload;
      saveNewChargeDocumentIntoLocalStorage(state.charges.document);
    },
    setChargeDocumentEmployeeAction: (state, action) => {
      state.charges.document.employee = action.payload;
      saveNewChargeDocumentIntoLocalStorage(state.charges.document);
    },
    setCurrentChargeDocumentItemsAction: (state, action) => {
      state.charges.document.documentItems = action.payload;
      saveNewChargeDocumentIntoLocalStorage(state.charges.document);
    },
    setCurrentPostingDocumentItemsAction: (state, action) => {
      state.postings.document.documentItems = action.payload;
    },
    setCurrentShiftingDocumentItemsAction: (state, action) => {
      state.shiftings.document.documentItems = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchChargesAsyncAction.fulfilled, (state, action) => {
        state.charges.list = action.payload.result;
      })
      .addCase(fetchPostingsAsyncAction.fulfilled, (state, action) => {
        state.postings.list = action.payload.result;
      })
      .addCase(fetchShiftingsAsyncAction.fulfilled, (state, action) => {
        state.shiftings.list = action.payload.result;
      });
  }
});

export const {
  resetChargeDocumentAction,
  setChargeDocumentAction,
  setChargeDocumentDate,
  setChargeDocumentNumber,
  setChargeDocumentStorageAction,
  setChargeDocumentEmployeeAction,
  setCurrentChargeDocumentItemsAction,
  setCurrentPostingDocumentItemsAction,
  setCurrentShiftingDocumentItemsAction
} = documents.actions;
