import {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {setInventoryDataAction} from "redux/slices/inventorySlice";

import TableRow from 'components/table-row/table-row';
import AddNewLineButton from 'components/add-new-line-button/add-new-line-button';

const TableBody = ({
  paginationData,
  setPaginationData,
  firstItemIndex,
  displayedItems
}) => {
  const dispatch = useDispatch();

  // Номер подсвечиваемой строки, когда пользователь задублировал
  // название при добавлении нового товара и программа по нажатию
  // кнопки перекинула его к уже созданному товару с таким же именем
  const [outlinedRow, setOutlinedRow] = useState(null);

  // Выборка данных из стейта селекторами
  const inventoryItems = useSelector((state) => state.inventory).inventory.data;

  // Новая строка
  const addLine = () => {
    const copy = structuredClone(inventoryItems);
    const emptyLine = {
      name: "",
      uchet: 0,
      fact: 0,
      spread: 0,
      total_uchet: 0,
      total_fact: 0,
      datas: [
        {
          spread: "",
          amount_fact: "",
          address_fact: "",
          amount_uchet: "",
          address_uchet: "",
        },
      ],
      inventory_id: copy[0].inventory_id,
      map_id: copy[0].map_id,
    };

    const updatedItems = [
      ...copy.slice(0, paginationData.itemsPerPage * paginationData.activePage - 1),
      emptyLine,
      ...copy.slice(paginationData.itemsPerPage * paginationData.activePage - 1)
    ];

    dispatch(setInventoryDataAction(updatedItems));
  };
  
  return displayedItems.length && (
    <tbody>
      {displayedItems.map((item, index) => (
        <TableRow
          key={item.id}
          item={item}
          index={firstItemIndex + index}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          outlinedRow={outlinedRow}
          setOutlinedRow={setOutlinedRow}
        />
      ))}

      {/* Добавить наименование */}
      {
        inventoryItems.length > 0 &&
        inventoryItems[0].provided === 0 && (
          <AddNewLineButton title='Добавить наименование' addLine={addLine}/>
        )
      }
      <tr style={{ height: "1px" }} />
    </tbody>
  );
};

export default TableBody;
