import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiRoute} from 'constants/endpoints';

export const FormType = {
  CHARGE: 'Списание',
  POSTING: 'Оприходование',
  SHIFTING: 'Перемещение'
};

export const fetchChargesAsyncAction = createAsyncThunk(
  'documents/fetchCharges',
  async (_args, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.GET_FORMS}?type=${FormType.CHARGE}`);

    return data;
  }
);

export const postDocumentAsyncAction = createAsyncThunk(
  'documents/postDocument',
  async (body, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Fields.SAVE_FORM, body);

    return data;
  }
);

export const fetchPostingsAsyncAction = createAsyncThunk(
  'documents/fetchPostings',
  async (_args, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.GET_FORMS}?type=${FormType.POSTING}`);

    return data;
  }
);

export const fetchShiftingsAsyncAction = createAsyncThunk(
  'documents/fetchShiftings',
  async (_args, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.GET_FORMS}?type=${FormType.SHIFTING}`);

    return data;
  }
);
