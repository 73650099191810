export const EMPLOYEE_SETTING_DELAY = 1000;
export const VALIDATE_EMPLOYEE_DELAY = 1000;

export const debounce = (callback, delay) => {
  let timeoutId;
  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback.apply(this, arguments), delay);
  };
};

export const getInputValue = (evt) => {
  const inputType = evt.target.type;

  const InputType = {
    FILE: 'file',
    TEXT: 'text',
  };

  switch(inputType) {
    case InputType.TEXT:
      return evt.currentTarget.value;
    case InputType.FILE:
      return evt.target.files[0];
    default:
      return evt.target.value;
  }
};

export const getDateTimeStringFromDBValue = (initialValue) => {
  const currentDate = initialValue;
  const YYYY = `${currentDate[6]}${currentDate[7]}${currentDate[8]}${currentDate[9]}`;
  const MM = `${currentDate[3]}${currentDate[4]}`;
  const DD = `${currentDate[0]}${currentDate[1]}`;
  const HH = `${currentDate[11]}${currentDate[12]}`;
  const mm = `${currentDate[14]}${currentDate[15]}`;
  const dateTimeString = `${YYYY}-${MM}-${DD}T${HH}:${mm}:00.000Z`;

  return dateTimeString;
};

export const getOptionsList = (options, value) => {
  const processedOptions = structuredClone(options).sort((a, b) => a.value.toLowerCase() > b.value.toLowerCase());
  const errorOptions = [{name: 'Ошибка', value: 'Ошибка'}];

  // если есть значение, то отфильтровать опции, которые:
  if (value.trim()) {
    // - начинаются с него
    const startWithValueOptions = [];
    // - содержат его, но не начинаются с него
    const includesValueOptions = [];
    // - добавить все остальные
    const theRestOfOptions = [];

    // фильтруем
    for(let i = 0; i < processedOptions.length; i++) {
      if (processedOptions[i].value.toLowerCase().startsWith(value.toLowerCase())) {
        startWithValueOptions.push(processedOptions[i]);
      }
      if (processedOptions[i].value.toLowerCase().includes(value.toLowerCase()) && !processedOptions[i].value.toLowerCase().startsWith(value.toLowerCase())) {
        includesValueOptions.push(processedOptions[i]);
      }
      if (!processedOptions[i].value.toLowerCase().includes(value.toLowerCase()) && !processedOptions[i].value.toLowerCase().startsWith(value.toLowerCase())) {
        theRestOfOptions.push(processedOptions[i]);
      }
    }

    // собираем
    const displayedOptions = [
      ...startWithValueOptions.sort((a, b) => a.value.toLowerCase() > b.value.toLowerCase()),
      ...includesValueOptions.sort((a, b) => a.value.toLowerCase() > b.value.toLowerCase()),
      ...theRestOfOptions.sort((a, b) => a.value.toLowerCase() > b.value.toLowerCase())
    ];

    // проверить кол-во опций на выходе - совпадает ли с кол-вом на входе (временная проверка)
    if (displayedOptions.length === processedOptions.length) {
      return displayedOptions;
    } else {
      return errorOptions;
    }
  } else {
    // если значения нет, то вернуть изначальные опции
    return processedOptions;
  }
};
