import {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import {fetchCurrentNames, fetchArchiveNames} from "redux/slices/historySlice";

import Button from "../../components/ButtonComponent";

import {AppContext} from "providers/AppContextProvider";

import cl from "styles/pages/[fieldId].module.scss";

const Select = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();

	const LOCATION_REG_EXP = /archive/;

	const id = params.id;
	const pathName = location.pathname
	const isInArchive = LOCATION_REG_EXP.test(pathName);

	const currentNames = useSelector((state) => state.history).currentNames.data;
	const archiveNames = useSelector((state) => state.history).archiveNames.data;
	const displayedNames = isInArchive && id ? archiveNames : currentNames;
	const dates = useSelector((state) => state.history).archiveNames.dates;
  
	const {setCrumbs} = useContext(AppContext);	
	// Классы таблиц
	const tableClasses = [cl.mainTable, cl.top0, "table", "table-responsive"];
	
	// Данные с сервера
	const [names, setNames] = useState([]);
	
	// Строка поиска
	const [search, setSearch] = useState("");

	const handleGoodNameCellClick = (goodName) => {
		if (isInArchive && id) {
			navigate(`/archive/history/${id}/item?name=${goodName}`);
		} else {
			navigate(`/history?name=${goodName}`);
		}
	};
	
	// Запись хлебных крошек
	useEffect(() => {
		const crumbs = isInArchive && id
			? [
					{name: "Архивы", url: "/archive"},
					{name: "История", url: "/archive/history"},
					{name: "Сезон " + dates, url: `/archive/history/${id}`}
				]
			: [
					{name: "История", url: ""}
				];

		setCrumbs(crumbs);
	}, []);

	// Обработка поискового запроса
	useEffect(() => {
		if(search.trim() === ""){
			setNames(displayedNames);
			return;
		}
		
		setNames(displayedNames.filter((name) => {
			return name.toLowerCase().includes(search.toLowerCase());
		}));
	}, [search, displayedNames]);

	// Запрос данных с сервера
	useEffect(() => {
		if (isInArchive && id) {
			dispatch(fetchArchiveNames(id));
		} else {
			dispatch(fetchCurrentNames());
		}
	}, []);
	
	return (
		<>
			<title>Текущие движения товаров</title>
			
			<table className={tableClasses.join(" ")}>
				<thead className={cl.top0 + " theadBordered thead-dark"}>
					<tr>
						<th>
							Название
						</th>
						<th>
							<input
								type="text"
								value={search}
								placeholder="Поиск по наименованию"
								style={{maxWidth: "100%"}}
								onChange={(e) => setSearch(e.target.value)}
							/>
						</th>
						<th style={{width: "300px"}}>
							<Button type="default" onClick={() => window.open("/faq/history", "_blank")}>Инструкция</Button>
						</th>
					</tr>
				</thead>
				<tbody>
					{
						names.length > 0 && names.map(name =>
							<tr key={name}>
								<td
									colSpan="3"
									className={cl.router}
									onClick={() => handleGoodNameCellClick(name)}
								>
									{name}
								</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</>
	);
};

export default Select;