import {useEffect, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import ChargesListButtonsBlock from 'components/charges-list-buttons-block/charges-list-buttons-block';
import ChargesListItems from 'components/charges-list-items/charges-list-items';
import ListLegend from 'components/list-legend/list-legend';

import {fetchChargesAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {getChargesList} from 'redux/slices/documents/selectors';

import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';
import {AppRoute} from 'constants/routes';

import {AppContext} from 'providers/AppContextProvider';

import styles from './styles.module.scss';

const ChargesList = () => {
  const dispatch = useDispatch();

  const {setCrumbs} = useContext(AppContext);

  const chargesListItems = useSelector(getChargesList);

  useEffect(() => {
    dispatch(fetchChargesAsyncAction());
  }, [dispatch]);

  // Запись хлебных крошек
  useEffect(() => {
    setCrumbs([
      {name: DocumentTypeNameMap[DocumentType.CHARGE], url: AppRoute.Document.CHARGES}
    ]);
  }, []);

  return (
    <div className={styles.wrapper}>
      <ListLegend documentType={DocumentTypeNameMap[DocumentType.CHARGE]}/>
      <ChargesListButtonsBlock/>
      <ChargesListItems chargesListItems={chargesListItems}/>
    </div>
  );
};

export default ChargesList;
