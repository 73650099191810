import cl from "styles/pages/[fieldId].module.scss";
import fcl from "styles/components/Filter.module.scss";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Fragment, useContext, useEffect, useState} from "react";
import axios from "axios";

import Filter from "components/Filter";

import {
  fetchAmountAll,
  fetchAmountData,
  fetchAmountDataWithRemains,
  fetchJournalName,
} from "redux/slices/journalSlice";
import {fetchGroups, fetchStores, fetchGoods} from "redux/slices/businessSlice";
import {fetchMapFields, fetchMapAddresses} from "redux/slices/mapSlice";

import {AppContext} from "providers/AppContextProvider";
import {loadingStatus} from "helpers/fetcher";

const Amount = () => {
	const navigate = useNavigate();

	const {setCrumbs} = useContext(AppContext);
	const dispatch = useDispatch();
	const {
		amountAll,
		amountData,
		amountDataWithRemains,
		journalName,
	} = useSelector((state) => state.journal);
	const {groups, stores} = useSelector((state) => state.business);
	const {goods, articles} = useSelector((state) => state.good);
	const {mapAddresses, mapFields} = useSelector((state) => state.map);

	// Приходящие данные
	const [plants, setPlants] = useState([]);

	// Суммы
	const [amount, setAmount] = useState(0);

	// Поисковые состояния
	const [search, setSearch] = useState("");
	const [dataBackup, setDataBackup] = useState({});

	// Показ второй таблицы
	const [isAmountOpen, setIsAmountOpen] = useState(false);

	// Поле сортировки и направление
	const [sorted, setSorted] = useState("storage");
	const [sortDir, setSortDir] = useState(true);

	// Классы таблиц
	const tableClasses = [cl.mainTable, "table", "table-responsive"];
	const amountTableClasses = ["table", "table-hover", "table-responsive"];

	// Показ фильтра
	const [isFilterOpen, setIsFilterOpen] = useState(false);

	// Асинхронные данные из Бизнес.ру
	const [plantsNames, setPlantsNames] = useState([]);
	const [plantsArticles, setPlantsArticles] = useState([]);

	// Смена названия журнала
	const changeName = (e) => {
		axios.post("journals/setJournalName/2", {
			name: e.currentTarget.textContent
		}).then(() => {
			alert("Успешно сохранено", "success");
		}).catch(() => {
			alert("Возникли ошибки со стороны сервера", "danger");
		});
	}

	// Печать (скачивание)
	const print = () => {
		axios.post("journals/print", {
			body: plants,
			amount: amount,
			amountAll: amountAll.data
		}).then(response => {
			navigate("/FILES/" + response.data.filename);
      setTimeout(() => navigate("/journals/amount"), 1);
		}).catch(() => {
			alert("Ошибка скачивания", "danger");
		});
	}

	// Функция сортировки растений
	const sortPlants = (name) => {
		setSortDir(!sortDir);
		
		const pl = Object.assign([], plants);
		
		pl.sort((a, b) => {
			if(name === "b_group" && a[name] === b[name]){
				if(a.name === b.name) return 0;
				else return a.name > b.name ? 1 : -1;
			} else if(a[name] === b[name]) return 0;
			else if(!sortDir === true) return a[name] > b[name] ? 1 : -1;
			else return a[name] < b[name] ? 1 : -1;
		});
		
		setPlants(pl);
		setSorted(name);
	}

  useEffect(() => {
		dispatch(fetchAmountAll());
		dispatch(fetchAmountData());
		dispatch(fetchJournalName(2));

		dispatch(fetchGroups());
		dispatch(fetchStores());
		dispatch(fetchMapFields());
		dispatch(fetchMapAddresses());


		dispatch(fetchGoods());
		dispatch(fetchAmountDataWithRemains());
  }, []);

  useEffect(() => {
		if (amountData.data.length > 0) {
		  setPlants(amountData.data);
		}
  }, [amountData.data]);

	// Создание копии начальных данных
	useEffect(() => {
    if (plants.length > 0) {
		  let copy = Object.assign([], plants);
		  setDataBackup(copy);
    }
	}, [plants]);

	// Поиск
	useEffect(() => {
		// Возврат начальных данных при очистке поисковой строки
		if(!search.length && Object.keys(dataBackup).length > 0){
			setPlants(dataBackup);
			return;
		} else if(!dataBackup.length) return;
		
		// Перебор начальных данных по найденным ключам
		setPlants(dataBackup.filter(pl => {
			return pl.name.toLowerCase().includes(search.toLowerCase());
		}));
	}, [search]);

	// Подсчет сумм
	useEffect(() => {
		let count = 0;
		plants.forEach(pl => {
			count += pl.amount;
		});
		setAmount(count);
	}, [plants]);

	useEffect(() => {
		if (goods) {
			setPlantsNames(goods);
		}
		setPlantsArticles(articles);
	}, [goods, articles]);

	useEffect(() => {
		// Запрос складских остатков
    if (amountDataWithRemains.data.length > 0) {
      setPlants(amountDataWithRemains.data);
      setDataBackup(amountDataWithRemains.data);
		}
	}, [amountDataWithRemains.data]);

	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([{name: journalName, url: ""}]);
	}, []);
	
  if (amountAll.status !== loadingStatus.SUCCEEDED) return null;

	return (
		<>
      <title>Сводный журнал</title>

			<div className="stickyContainer" onClick={() => {
				if(isAmountOpen) setIsAmountOpen(false);
				if(isFilterOpen) setIsFilterOpen(false);
			}}>
				<table className={tableClasses.join(" ")} style={{marginTop: 0}}>
					<thead className="theadBordered thead-dark" style={{top: 0}}>
						<tr>
							<th className="search" colSpan="2">
								<input
									type="text"
									value={search}
									placeholder="Поиск"
									style={{maxWidth: "100%"}}
									onChange={(e) => setSearch(e.target.value)}
								/>
							</th>
							<th colSpan="8">
								<span contentEditable={true} onBlur={changeName} className="transparent">{journalName}</span>
							</th>
							<th>
								<span className="roundIcon material-icons" onClick={() => window.open("/faq/journalAmount", "_blank")} title="Инструкция">
									quiz
								</span>
							</th>
							<th>
								<span
									className="roundIcon material-icons"
									onClick={() => navigate("/journals/spread")}
									title="Отчет о расхождениях"
								>
									swap_horiz
								</span>
							</th>
							<th>
								<span
									className="roundIcon material-icons"
									onClick={print}
									title="Скачать"
								>
									download
								</span>
							</th>
							<th>
								<span
									className="roundIcon material-icons"
									onClick={() => setIsFilterOpen(!isFilterOpen)}
									title="Фильтр"
								>
									filter_alt
								</span>
							</th>
							<th>
								<span
									className="roundIcon material-icons"
									onClick={() => setIsAmountOpen(!isAmountOpen)}
									title="Данные по всем полям"
								>
									format_list_bulleted
								</span>
							</th>
						</tr>
						<tr>
							<th colSpan="6" className="text-end">Всего растений (шт):</th>
							<th colSpan="2">{amount}</th>
							<th rowSpan="2">Общий остаток</th>
							<th colSpan="2">в том числе</th>
							<th rowSpan="2">В закупке</th>
							<th rowSpan="2">Дата (план)</th>
							<th rowSpan="2">В производстве</th>
							<th rowSpan="2">Дата (план)</th>
						</tr>
						<tr>
							<th>№</th>
							<th
								className={sorted === "name" ? "sort sorted" : "sort"}
								onClick={() => sortPlants("name")}
							>
								<span>Наименование</span>
							</th>
							<th
								className={sorted === "article" ? "sort sorted" : "sort"}
								onClick={() => sortPlants("article")}
							>
								<span>Артикул</span>
							</th>
							<th
								className={sorted === "b_group" ? "sort sorted" : "sort"}
								onClick={() => sortPlants("b_group")}
							>
								<span>Группа</span>
							</th>
							<th>ШТУК всего</th>
							<th>Кол-во</th>
							<th>Адрес</th>
							<th>Склад</th>
							<th>Резерв</th>
							<th>Свободно</th>
						</tr>
					</thead>
					<tbody>
						{
							plants.map((plant, index) =>
								<Fragment key={index.toString()}>
									{plant.positions.length > 0 && plant.positions.map((pos, ind) =>
										<tr key={ind.toString()}>
											{ind === 0 &&
												<>
													<td rowSpan={plant.positions.length} className={cl.center + " " + cl.cifer}>
														{index + 1}
													</td>
													<td
														rowSpan={plant.positions.length}
														className={cl.name + " " + cl.router}
														onClick={() => navigate("/history?name=" + plant.name)}
													>
														{plant.name}
													</td>
													<td
														rowSpan={plant.positions.length}
														className={cl.router}
														onClick={() => navigate("/history?name=" + plant.name)}
													>
														{plant.article}
													</td>
													<td rowSpan={plant.positions.length}>{plant.b_group}</td>
													<td rowSpan={plant.positions.length} className={cl.right}>
														{new Intl.NumberFormat("ru-RU").format(plant.amount)}
													</td>
												</>
											}

											<td className={cl.right}>{new Intl.NumberFormat("ru-RU").format(pos.amount)}</td>
											<td>{pos.address}</td>
											<td>{pos.storage}</td>

											{ind === 0 &&
												<>
													<td rowSpan={plant.positions.length} className={cl.right}>
														{
															!plant.remains
																? <span className="material-icons spinner">rotate_right</span>
																: new Intl.NumberFormat("ru-RU").format(plant.remains.total)
														}
													</td>
													<td rowSpan={plant.positions.length} className={cl.right}>
														{
															!plant.remains
																? <span className="material-icons spinner">rotate_right</span>
																: new Intl.NumberFormat("ru-RU").format(plant.remains.reserved)
														}
													</td>
													<td rowSpan={plant.positions.length} className={cl.right}>
														{
															!plant.remains
																? <span className="material-icons spinner">rotate_right</span>
																: new Intl.NumberFormat("ru-RU").format(parseInt(plant.remains.total) - parseInt(plant.remains.reserved))
														}
													</td>
													<td rowSpan={plant.positions.length} className={cl.right}>
														{plant.delivery && new Intl.NumberFormat("ru-RU").format(parseInt(plant.delivery.amount))}
													</td>
													<td rowSpan={plant.positions.length}>
														{plant.delivery && plant.delivery.date}
													</td>
													<td rowSpan={plant.positions.length} className={cl.right}>
														{plant.factory && new Intl.NumberFormat("ru-RU").format(parseInt(plant.factory.amount))}
													</td>
													<td rowSpan={plant.positions.length}>
														{plant.factory && plant.factory.date}
													</td>
												</>
											}
										</tr>
									)}
								</Fragment>
							)
						}
					</tbody>
				</table>
			</div>

			<div
				className={fcl.filterContainer + (isAmountOpen ? " " + fcl.open : "")}
				style={{padding: "0", maxHeight: "80vh", overflowX: "auto"}}
			>
				<table className={amountTableClasses.join(" ")}>
					<thead className="theadBordered thead-dark">
						<tr>
							<th>Поле</th>
							<th>ШТУК всего</th>
						</tr>
					</thead>
					<tbody>
						{
							amountAll.data.map((field, index) =>
								<Fragment key={field.field}>
									{(index === 0 || field.storage !== amountAll.data[index - 1].storage) &&
										<tr>
											<td
												colSpan="2"
												className={fcl.accent + " " + fcl.router}
												onClick={() => window.open("/journals/amount/?storage=" + field.storage, "_blank")}
											>
												{field.storage}
											</td>
										</tr>
									}
									<tr>
										<td
											className={fcl.router}
											onClick={() => window.open("/map/" + field.map_id + "/fields/" + field.map_field_id, "_blank")}
										>
											{field.field}
										</td>
										<td
											className={fcl.router}
											onClick={() => window.open("/map/" + field.map_id + "/fields/" + field.map_field_id, "_blank")}
										>
											{field.amount}
										</td>
									</tr>
								</Fragment>
							)
						}
					</tbody>
				</table>
			</div>
			<Filter
        isOpen={isFilterOpen}
        data={plants}
        setData={setPlants}
        currentSort={sorted}
        sortFn={sortPlants}
        storages={stores.data}
        addressList={mapAddresses.data}
        fieldList={mapFields.data}
        groups={groups.data}
        plants={plantsNames}
        articles={plantsArticles}
        multiple={true}
      />
		</>
	);
};

// export async function getServerSideProps(){
// 	const apiToken = "Bearer ~ybqF7}S9G9a5@8}aIex";
	
// 	// Данные по всем полям
	// const dataResponse = await axios.get("journals/amountData", {
	// 	headers: {
	// 		Authorization: apiToken
	// 	}
	// });
	// const data = dataResponse.data.result;
	
// 	// Сортировка числовых значений в обход строковых
// 	data.map((dt, ind) => {
// 		data[ind].positions = dt.positions.sort((a, b) => {
// 			if(a.address === b.address) return 0;
// 			else{
// 				const aa = a.address.split(".")[0];
// 				const bb = b.address.split(".")[0];
				
// 				if(aa === bb || isNaN(parseInt(aa)) || isNaN(parseInt(bb))) return 0;
// 				else return parseInt(aa) > parseInt(bb) ? 1 : -1;
// 			}
// 		});
// 	});
	
// 	// Общее количество по всем полям
// 	const aResponse = await axios.get("journals/amountAll", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const amountAll = aResponse.data.result.sort((a, b) => {
// 		return a.sort_name > b.sort_name && a.storage === b.storage ? 1 : -1;
// 	}).sort((a, b) => {
// 		if(a.storage === b.storage) return 0
// 		else return a.storage > b.storage ? 1 : -1;
// 	});
	
// 	// Название журнала
// 	const jResponse = await axios.get("journals/journalName/2", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const journalName = jResponse.data.result;
	
// 	// Запрос всех складов
// 	const storesResponse = await axios.get("businessRu/getStores", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const stores = storesResponse.data.result;
	
// 	// Запрос всех групп
// 	const groupsResponse = await axios.get("businessRu/getGroups", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const groups = groupsResponse.data.result;
	
// 	// Запрос всех адресов
// 	const addressListResponse = await axios.get("map/getAllAddresses", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const addressList = addressListResponse.data.result;
	
// 	// Запрос всех полей
// 	const fieldListResponse = await axios.get("map/getAllFields", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const fieldList = fieldListResponse.data.result;
	
// 	return {props: {data, journalName, amountAll, stores, groups, addressList, fieldList}}
// }

export default Amount;