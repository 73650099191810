import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";

import InputDate from "components/input/InputDate";

import {fetchArchiveInventories} from 'redux/slices/inventorySlice';

import {getUser} from 'helpers/storage';
// import {AppContext} from "providers/AppContextProvider";
import {AppRoute} from "constants/routes";

import cl from "styles/components/cabinet/UsersTable.module.scss";

const InventoryTable = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// const {alert} = useContext(AppContext);

	const userLevel = getUser().accessLevel;

	const archiveInventories = useSelector((state) => state.inventory).inventories.archive;
	
	// Периоды фильтрации
	const [period, setPeriod] = useState({before: "", after: ""});
	
	// Строка поиска
	const [search, setSearch] = useState("");

	// Запрос данных с сервера
	useEffect(() => {
		dispatch(fetchArchiveInventories());
	}, [archiveInventories.length]);
	
	// Фильтрация
	// useEffect(() => {
	// 	let filtered = Inventory;
	// 	if(period.after !== "")
	// 		filtered = filtered.filter(inventory => new Date(inventory.document_date_filter).valueOf() >= new Date(period.after + " 00:00").valueOf());
	// 	if(period.before !== "")
	// 		filtered = filtered.filter(inventory => new Date(inventory.document_date_filter).valueOf() <= new Date(period.before + " 23:59").valueOf());
	// 	if(search !== "")
	// 		filtered = filtered.filter(inventory => {
	// 			if(inventory.comment) return inventory.comment.toLowerCase().includes(search.toLowerCase());
	// 			else return false;
	// 		});
		
	// 	setInventory(filtered);
	// }, [period, search]);
	
	// Изменение периода фильтрации
	const changePeriod = (name, value) => {
		const copy = Object.assign({}, period);
		copy[name] = value;
		setPeriod(copy);
	}
	
	// Удаление архива
	// const remove = (index) => {
	// 	const copy = Object.assign([], inventory);
		
	// 	alert("Удалить архив?", "danger", 10000, [
	// 		{
	// 			text: "Да",
	// 			handler: function(){
	// 				axios.delete("inventory/remove/" + copy[index].id).then(() => {
	// 					axios.delete("inventory/remove/" + copy[index].copy).then(() => {
	// 						alert("", "default", 1);
	// 						copy.splice(index, 1);
	// 						setInventory(copy);
	// 					}).catch(() => {
	// 						alert("Ошибка удаления", "danger");
	// 					});
	// 				}).catch(() => {
	// 					alert("Ошибка удаления", "danger");
	// 				});
	// 			}
	// 		},
	// 		{
	// 			text: "Нет",
	// 			handler: function(){
	// 				alert("", "default", 1);
	// 			}
	// 		}
	// 	]);
	// }

	const handleShowInventoryButtonClick = (id, copyInventoryId) => {
		navigate(`${AppRoute.Archive.INVENTORY}/${id}`);
		window.open(`${AppRoute.Archive.INVENTORY}/${copyInventoryId}`, "_blank");
	};
	
	return (
		<table className={cl.usersTable}>
			<thead>
				<tr>
					<th colSpan="2">
						<div style={{display: "inline-block", width: "45%"}}>
							<InputDate name="after" currentValue={period.after} changeHandler={changePeriod}/>
						</div>
						<div style={{display: "inline-block", width: "4%"}}>—</div>
						<div style={{display: "inline-block", width: "45%"}}>
							<InputDate name="before" currentValue={period.before} changeHandler={changePeriod}/>
						</div>
					</th>
					<th colSpan={userLevel === 3 ? "4" : "3"}>
						<input type="search" name="commentSearch" value={search} title={search}
								 placeholder="Поиск по примечанию" onChange={(e) => setSearch(e.target.value)}/>
					</th>
				</tr>
				<tr>
					<th>№</th>
					<th>Дата инвентаризации</th>
					<th>Дата в Системе</th>
					<th>Склад</th>
					<th/>
					{userLevel === 3 && <th/>}
				</tr>
			</thead>
			<tbody>
				{archiveInventories.map((inv, index) =>
					<tr key={index.toString()}>
						<td>
							<span>{inv.document_number}</span>
						</td>
						<td>
							<span>{inv.date}</span>
						</td>
						<td>
							<span>{inv.date_current}</span>
						</td>
						<td>
							<span>{inv.storage}</span>
						</td>
						<td>
							<span
								className={cl.icon + " material-icons"}
								title="Посмотреть"
								onClick={() => handleShowInventoryButtonClick(inv.id, inv.copy)}
							>
								visibility
							</span>
						</td>
						{userLevel === 3 &&
							<td>
								<span
									className={cl.icon + " " + cl.remove + " material-icons"}
									title="Удалить"
									// onClick={() => remove(index)}
								>
									close
								</span>
							</td>
						}
					</tr>
				)}
			</tbody>
		</table>
	);
};

export default InventoryTable;